<template>
    <div class="container-fluid mb-4" style="margin-top:20px;">
        <modalVehiculo
            :modal.sync="showModal"
            @updated-modal="updateCLoseModal"
            :title="titleModal"
            :editModal="editModal"
            @refresh-data-table="resetDataOfTable"
            :objectVehicle="objectVehicle"
        />
        <AlertModal
            :modal.sync="alertModal"
            :staffList="staffFile"
            :fileName="fileName"
            :type="2"
            :description="description"
        />
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow>
            <CCol sm="12">
                <CRow>
                    <CCol sm="6">
                        <CSelect
                            :label="$t('label.transport')"
                            :horizontal="{
                                label: 'col-sm-12 col-lg-4 text-right',
                                input: 'col-sm-12 col-lg-8',
                            }"
                            addLabelClasses="required"
                            :options="companyOptions"
                            :value.sync="ClientTpId"
                            :is-valid="hasError($v.ClientTpId)"
                            @change="resetTable()"
                            v-model.trim="$v.ClientTpId.$model"
                            :invalid-feedback="errorMessage($v.ClientTpId)"
                        ></CSelect>
                    </CCol>
                    <CCol sm="1">
                        <CButton color="excel" size="sm" v-c-tooltip="{content: $t('label.DownloadFormat'),placement: 'top'}" :href="this.$store.getters['connection/getBase']+'download/FormatoMigracionVehiculos.xlsx'">
                            <CIcon name="cil-file-excel" />
                        </CButton>
                    </CCol>
                    <CCol sm="5" class="d-flex align-items-start justify-content-end">
                        <CButton
                            color="add"
                            size="sm"
                            @click="toggleShowModalCreateChofer()"
                            v-c-tooltip="{ placement:'top', content:$t('label.vehicle') }"
                            >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{ $t('label.nuevo') }}
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('label.UploadFile')">
                        <CCard bodyWrapper style="padding:5px;" class="form-especial">
                            <loading-overlay :active="isSubmit" :is-full-page="false" loader="bars"/>
                            <CRow class="mb-3">
                                <CCol sm="12" class="mb-3">
                                    <vue-dropzone 
                                        :aria-disabled="idIndentification" 
                                        ref="myVueDropzone"
                                        id="dropzone"
                                        :options="dropZoneOptions"
                                        :useCustomSlot="true"
                                        v-on:vdropzone-files-added="sendingEvent"
                                        v-on:vdropzone-removed-file="deleteFile"
                                        v-on:vdropzone-error="DropzoneError"
                                    > 
                                        <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title">
                                                <CIcon name="cil-cloud-upload" /> ¡{{$t('label.dragAndDropToUploadContent')}}!
                                            </h3>
                                            <div class="subtitle">
                                                ...{{$t('label.orClickToSelectFileFromYourComputer')}}
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                </CCol>
                                <CCol sm="11">
                                    <CInput
                                        :label="$t('label.description')"
                                        horizontal
                                        addLabelClasses="required text-right"
                                        :placeholder="$t('label.documentDescription')"
                                        v-uppercase
                                        v-model="$v.formArchivos.description.$model"
                                        :addInputClasses="{ 'is-invalid': $v.formArchivos.description.$error }"
                                        @blur="$v.formArchivos.description.$touch()"
                                        :is-valid="hasError($v.formArchivos.description)"
                                    >
                                        <template #invalid-feedback>
                                            <div v-if="$v.formArchivos.description.$error">
                                                <div class='text-invalid-feedback' v-if="!$v.formArchivos.description.required">{{$t('label.required')}}</div>
                                                <div class='text-invalid-feedback' v-if="!$v.formArchivos.description.minLength">{{$t('label.required')}} {{$t('label.min')}} {{$v.formArchivos.description.$params.minLength.min}} {{$t('label.chars')}}</div>
                                                <div class='text-invalid-feedback' v-if="!$v.formArchivos.description.maxLength">{{$t('label.required')}} {{$t('label.max')}} {{$v.formArchivos.description.$params.maxLength.min}} {{$t('label.chars')}}</div>
                                            </div>
                                        </template>
                                    </CInput>
                                </CCol>
                                <CCol sm="1"  class="d-flex align-items-start justify-content-end">
                                    <CButton 
                                        color="add"
                                        size="sm"
                                        v-c-tooltip="{
                                            content: $t('label.add'),
                                            placement: 'top-end'
                                        }"
                                        @click="submitFile()"  
                                        :disabled="idIndentification"
                                    >
                                        <CIcon name="checkAlt"/>
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCard>
                    </CTab>
                </CTabs>
                <br>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="items"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :loading="loadingTable"
                    hover
                    sorter
                    >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #Status="{ item }">
                        <td class="text-center align-middle">
                            <div>
                                <CBadge :color="getBadge(item.Status)">
                                {{ $t("label." + item.Status) }}
                                </CBadge>
                            </div>
                        </td>
                    </template>
                    <template #Detalle="{ item }">
                        <td class="text-center">
                            <CButton color="edit" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.vehicle'),placement:'top-end'}" @click="toggleShowModalEditVehicle(item)">
                                <CIcon name="pencil" />
                            </CButton>
                                <!--<CButton color="wipe" size="sm" v-c-tooltip="{content: $t('label.delete')}" @click="ConfirmarEliminado(item)" >
                                    <CIcon name="cil-trash" />
                                </CButton>-->
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { StowagePlanningFile } from '@/_validations/planificacion-estiba/logistica-empresa';
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import AlertModal from "./alert-modal";
    import modalVehiculo from './modal-vehiculo';
    import ModalMixin from '@/_mixins/modal';
    function data() {
        return {
            items: [],
            loadingTable: false,
            StowagePlanningResourceId:'',
            loadingOverlay:false,
            refrescarTabla: false,
            showModal: false,
            editModal: false,
            titleModal: '',
            companyOptions:[],
            ClientTpId: '',
            uploadPercent:0,
            responsePercent:0,
            AlertModal:false,
            staffFile: [],
            fileName: "",
            objectVehicle:{},
            alertModal:false,
            description:'',
            //registro
            formArchivos: {
                description:'',
                RouteFile: "",
                Format: ".xlsx",
                Format2: ".xls"
            },
            docFile: null,
            isSubmit:false
        }
    }
    function resetTable() {
        this.StowagePlanningVehicleList();
    }
    function toggleShowModalCreateChofer() {
        this.showModal = true;
        this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.vehicle');
    }
    function toggleShowModalEditVehicle(item) {
        this.showModal = true;
        this.objectVehicle = item;
        this.titleModal = this.$t('label.edit')+' '+this.$t('label.vehicle')+' '+this.$t('label.plate')+': '+this.objectVehicle.LicensePlate;
        this.editModal = true;
    }
    function updateCLoseModal(event) {
        this.showModal = false;
        this.titleModal = '';
        this.editModal = false;
        this.objectVehicle = {};
    }
    function resetDataOfTable() {
        this.showModal = false;
        this.refrescarTabla = true;
    }
    async function StowagePlanningVehicleList () {
        this.loadingTable = true;
        this.items = [];
        
        let listado = Array;
        await this.$http.get("StowagePlanningVehicle-list", { StowagePlanningId: this.StowagePlanningId, StowagePlanningResourceId: this.ClientTpId })
        .then(response => {
            listado = [...response.data.data];
            this.items = listado.map(listado => Object.assign({}, this.items, {
                ...listado,
                Nro: listado.Nro,
                UltimoUsuario: listado.TransaLogin,
                FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
                Status: listado.Status,
                FgActStoPlaVeh: listado.FgActStoPlaVeh,
                _classes: ( listado.FgActStoPlaVeh ) ? '' : 'table-danger'
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingTable = false;
        });
    }
    async function listarCompany () {
        this.loadingOverlay = true;
        this.companyOptions = [];

        let listado = Array;
        if(!["", null, undefined].includes(this.StowagePlanningId)){
            await this.$http.get("StowagePlanningCarrier-list", { StowagePlanningId:this.StowagePlanningId, Filter: 'ACTIVO' })
            .then(response => {
                listado = [...response.data.data];
                this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
                    value: listado.StowagePlanningResourceId,
                    label: listado.ClientName
                }));

                this.companyOptions.unshift({
                    value: "",
                    label: this.$t('label.select')
                });
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(() => {
                this.loadingOverlay = false;
            });
        }
        
    }
    function sendingEvent(files) {
        if (["", null, undefined].includes(this.planificacionId)) {
            this.$refs.myVueDropzone.removeFile(files);
        }

        let ext = "";
        if(files[0].name)
            ext = files[0].name.substring(files[0].name.lastIndexOf('.')+1, files[0].name.length);
        // ['pdf','doc','docx','xlsx'].includes(ext.toLowerCase())
        let format = '.xls';
        if(`.${ext.toLowerCase()}` == this.formArchivos.Format ){
            this.docFile = files[0];
        } else if(`.${ext.toLowerCase()}` == this.formArchivos.Format2 ){
            this.docFile = files[0];
        }else if(format !== '' && `.${ext.toLowerCase()}` == format){
            this.docFile = files[0];
        }else{
            this.$refs.myVueDropzone.removeAllFiles();
            let msg = `${this.$t('label.docTypeInvalid')}`;
            this.$swal.fire({
                icon: 'error',
                title: '',
                text: msg
            })
        }
    }
    function deleteFile(file) {
        this.docFile = null;
    }
    function DropzoneError(file) {
        this.$refs.myVueDropzone.removeFile(file);
    }
    async function submitFile() {
        try {
            this.$v.ClientTpId.$touch();
            this.$v.formArchivos.description.$touch();
            if (!this.formArchivos.description) {
                throw this.$t("label.errorsPleaseCheck");
            }
            if (!this.ClientTpId || !this.docFile) {
                throw this.$t("label.errorsPleaseCheck");
            }
            this.isSubmit = true;
            let formData = new FormData();
            let locale = this.$i18n.locale;
            formData.append("StowagePlanningId", this.StowagePlanningId);
            formData.append("StowagePlanningResourceId", this.ClientTpId);
            formData.append("TpStowageFileId", "faaa44e9-8a3b-4a49-b45b-a7eeff0c815f");
            formData.append("FileDescription", this.formArchivos.description);
            formData.append("files", this.docFile);
            formData.append("Languaje", locale);
            this.$http.post("migracionConductoresChoferes", formData, {
                baseURL: `${this.$store.getters["connection/getApiBase"]}`,
                onUploadProgress: function (progressEvent) {
                this.uploadPercent = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                }.bind(this),
            })
            .then((response) => {
                if(response.data.data[0].Json.FgSave == 0) {
                    this.$notify({
                        group: "container",
                        title: "¡Error!",
                        text: response.data.data[0].Json.Response,
                        type: "error",
                    });
                } else {
                    this.$notify({
                        group: 'container',
                        title: '¡Exito!',
                        text: response.data.data[0].Json.Response,
                        type: "success"
                    });
                }
                this.staffFile = response.data.data[0].Json.ResponseJson;
                this.fileName = this.docFile.name;
                this.description = this.formArchivos.description;
                this.toggleAlert();
                this.limpiarDatos();
            })
            .catch((err) => {
                this.$notify({
                    group: "container",
                    title: "¡Error!",
                    text: err,
                    type: "error",
                });
            })
            .then(() => {
                //this.$refs.myVueDropzone.setOption("addRemoveLinks",true);
                this.isSubmit = false;
                this.uploadPercent = 0;
                this.responsePercent = 0;
            });
        } catch (e) {
            this.notifyError({ text: e });
        }
    }
    function toggleAlert() {
        this.alertModal = true;
    }
    function limpiarDatos(){
        this.formArchivos.description = "";
        this.formArchivos.RouteFile = "";
        this.formArchivos.Format2 = ".xls";
        this.formArchivos.Format = ".xlsx";
        this.uploadPercent = 0;
        this.responsePercent = 0;
        this.docFile = null;
        this.$refs.myVueDropzone.removeAllFiles();
        this.$nextTick(() => {
            this.$v.formArchivos.$reset();
        });
    }
    function ConfirmarEliminado(item) {
        this.$swal.fire(
        alertPropertiesHelpers(this, {
            text: `${this.$t('label.deleteQuestion')}: ${this.$t('label.vehicle')} ${this.$t('label.plate')}: ${item.LicensePlate}?`,
        })
        ).then((result) => {
            if (result.isConfirmed) {
                let res = [];
                this.loadingOverlay = true;
                let StowagePlanningVehicleJson = {
                    VehicleId: item.VehicleId,
                    StowagePlanningVehicleId: item.StowagePlanningVehicleId,
                    StowagePlanningResourceId: item.StowagePlanningResourceId,
                    VehicleClassifId: item.VehicleClassifId,
                    BrandId: item.BrandId,
                    ModelId: item.ModelId,
                    LicensePlate: item.LicensePlate,
                    Year: item.Year,
                    Color: item.Color,
                    Status: 0
                };
                this.$http.put("StowagePlanningVehicle-update", StowagePlanningVehicleJson, { root: 'StowagePlanningVehicleJson' })
                .then(response => {
                    res = [...response.data.data];
                    this.$notify({
                        group: 'container',
                        title: '¡Exito!',
                        text: res[0].Response,
                        type: "success"
                    });
                    this.loadingOverlay = false;
                    this.StowagePlanningVehicleList();
                }).catch((err) => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                    this.loadingOverlay = false;
                });
            }
        });
    }
    function getBadge(status) {
        switch (status) {
        case 'ACTIVO':
            return 'success'
        case 'INACTIVO':
            return 'danger'
        }
    }
    //computeds
    function fields(){
        return [
            { 
                key: 'Nro', 
                label: '#',
                _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { label: this.$t('label.vehiclePlate'), key: 'LicensePlate', _classes:'text-center'},
            { label: this.$t('label.brand'), key: 'BrandName', _classes:'text-center' },
            { label: this.$t('label.type'), key: 'VehicleClassifName', _classes:'text-center' },   
            { key: 'ClientName', label: this.$t('label.transport') ,sorter: true, _classes:'text-center' },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
            { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
            { 
                key: 'Detalle',
                label: '',
                sorter: false,
                filter: false,
                _style: 'width:1%; min-width: 45px;'
            }
        ];
    }
    function idIndentification() {
        return ["", null, undefined].includes(this.StowagePlanningId);
    }
    function dropZoneOptions() {
        const token = this.user.token;
        return {
            url: `${process.env.VUE_APP_API_BASE}files/`,
            autoProcessQueue: false,
            maxFiles: 1,
            addRemoveLinks: true,
            dictRemoveFile: `${this.$t('label.delete')}`,
            dictCancelUpload: `${this.$t('label.cancelUpload')}`,
            maxfilesexceeded(files) {
                this.removeAllFiles();
                this.addFile(files);
            },
            acceptedFiles: ".xls, .xlsx",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        };
    }
    export default {
        name:'vehiculos',
        data,
        props:['value'],
        mixins: [GeneralMixin,ModalMixin],
        directives: UpperCase,
        validations: StowagePlanningFile,
        methods:{
            resetTable,
            StowagePlanningVehicleList,
            sendingEvent,
            deleteFile,
            DropzoneError,
            limpiarDatos,
            toggleAlert,
            listarCompany,
            ConfirmarEliminado,
            updateCLoseModal,
            resetDataOfTable,
            toggleShowModalCreateChofer,
            toggleShowModalEditVehicle,
            submitFile,
            getBadge
        },
        computed:{
            fields,
            dropZoneOptions,
            idIndentification,
            ...mapState({
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVessel: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        components:{
            vueDropzone: vue2Dropzone,
            AlertModal,
            modalVehiculo
        },
        watch:{
            refrescarTabla: function () {
                if (this.refrescarTabla) {
                    this.StowagePlanningVehicleList();
                    this.refrescarTabla = false;
                }
            },
            value: function (newValue) {
                if ((newValue==2) && (this.StowagePlanning!="")) {
                    this.StowagePlanningVehicleList();
                    this.listarCompany();
                }
            },
            alertModal: function (val) {
                if (!val) {
                    this.staffFile = [];
                    this.fileName = "";
                    this.description = "";
                    this.StowagePlanningVehicleList();
                }
            },
        }
    }
</script>